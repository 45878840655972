
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Group, Text, Image, Rect, Circle } from 'react-konva';
import useImage from 'use-image';

import defaultAvatar from '../../Assets/images/avatar.png';
import gear from './newGear.svg';
import RoundedImageDefault from './RoundedImageDefault';
import truncate from '../../Utils/trancate';
import { checkRobotStatusColor } from 'Utils/robotUtils';
import * as types from 'Config/permissionConstant';
import { USER_STATUS } from 'Config/colors';

const RobotCanvasItem = ({
  robotData,
  operatorData,
  scale,
  zoomScale,
  memberItemMoving = false,
  myPermission,
  robotStatusObj,
  assets,
  userId,
  userRole,
  onClick = () => {},
  onDblClick = () => {},
  onContextMenu = () => {},
  onTransformEnd = () => {},
  onDragEnd = () => {},
  onPopupClose = () => {},
  draggable,
  ...props
}) => {
  const [contextMenu, setContextMenu] = useState({
    exist: false,
    value: false,
  });
  const operatorCount = operatorData?.length > 3 ? 3 : operatorData?.length;
  const imageRef = useRef();
  const contextTimer = useRef();
  const [img] = useImage(gear);

  const robot_x = robotData.robot_x;
  const robot_y = robotData.robot_y;
  const rotation = robotData.robot_rotation;
  const robot_placeholder = robotData.robot_placeholder;
  const shoot_robot_nick_name = robotData.shoot_robot_nick_name;
  const robotId = robotData.id;

  const onTouchStart = e => {
    contextTimer.current = setTimeout(() => {
      contextMenu.exist &&
        myPermission?.includes(types.EDIT_SETUP_SCREEN) &&
        !contextMenu.value &&
        onContextMenu(e, robotId);
    }, 1000);
  };

  const onTouchEnd = () => {
    if (contextTimer?.current) {
      clearTimeout(contextTimer?.current);
    }
  };

  const isRestriction = () => {
    return (
      userId &&
      userRole &&
      userRole == 3 &&
      !operatorData?.find(item => item.user == userId)
    );
  };
  const robotStatusColor = checkRobotStatusColor(robotStatusObj, !isRestriction());

  const popUpMessage = message => {
    toast.info(message);
  };

  useEffect(() => {
    if (rotation && img?.complete) {
      const degToRad = Math.PI / 180;

      const rotatePoint = ({ x, y }, deg) => {
        const rcos = Math.cos(deg * degToRad),
          rsin = Math.sin(deg * degToRad);
        return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
      };

      const topLeft = {
        x: -imageRef.current.width() / 2,
        y: -imageRef.current.height() / 2,
      };
      const current = rotatePoint(topLeft, imageRef.current.rotation());
      const rotated = rotatePoint(topLeft, rotation);
      const dx = rotated.x - current.x,
        dy = rotated.y - current.y;

      imageRef.current.rotation(rotation);
      imageRef.current.x(imageRef.current.x() + dx);
      imageRef.current.y(imageRef.current.y() + dy);
    }
  }, [rotation, img]);

  useEffect(() => {
    if (props?.imageSize === false || props?.imageSize === true) {
      setContextMenu(prev => ({
        ...prev,
        exist: true,
        value: props.imageSize,
      }));
    }
  }, [props?.imageSize]);

  return (
    <Group
      x={robot_x}
      y={robot_y}
      draggable={draggable && !isRestriction()}
      name={'group-container-' + robotId}
      offsetX={50}
      offsetY={50}
      onContextMenu={e => {
        e?.evt?.preventDefault();

        contextMenu.exist &&
          myPermission?.includes(types.EDIT_SETUP_SCREEN) &&
          !contextMenu.value &&
          !isRestriction() &&
          onContextMenu(e, robotId);
      }}
      onDblClick={() => {
        if (isRestriction()) {
          popUpMessage('You are not authorized to access this robot');
        } else {
          onTouchEnd();
          onDblClick(robotData.id,robotData,'ROBOT');
        }
      }}
      onClick={() => {
        if (isRestriction()) {
          popUpMessage('You are not authorized to access this robot');
        } else {
          onClick(robotId);
          onTouchEnd();
        }
      }}
      onDragMove={e => {
        if (!isRestriction()) {
          onPopupClose();
          onTouchEnd();
        }
      }}
      onDragEnd={e => {
        if (!isRestriction()) {
          onDragEnd(e);
        }
      }}
      onDblTap={() => {
        onTouchEnd();
        onDblClick(robotData.id,robotData,'ROBOT');
      }}
      onTap={() => {
        onClick();
      }}
      onTouchMove={e => {
        onPopupClose();
        onTouchEnd();
      }}
      onTouchStart={onTouchStart}
      onTouchEnd={e => {
        onTouchEnd();
        onDragEnd(e);
      }}
      scaleX={zoomScale ? (0.25 / zoomScale) * scale : 0.25 / scale}
      scaleY={zoomScale ? (0.25 / zoomScale) * scale : 0.25 / scale}
    >
      <Rect
        height={150}
        width={100}
        fill={'transparent'}
        shadowColor={'#101828'}
        shadowOffsetX={0}
        shadowOffsetY={4}
        shadowBlur={8}
        cornerRadius={[6, 6, 0, 0]}
        shadowOpacity={0.1}
      />
      <Image
        image={img}
        x={24.5}
        y={18}
        ref={imageRef}
        rotation={0}
        name={'group-container-image-' + robotId}
        onTransformStart={e => {
          onPopupClose?.();
        }}
        onTransformEnd={e => {
          onTouchEnd();
          onTransformEnd(robotId, e);
        }}
      />
      <Group fill={'#333'} height={120} width={100}>
        <Rect
          height={20}
          width={100}
          fill={'#fff'}
          offsetY={-100}
          shadowColor={'#101828'}
          shadowOffsetX={0}
          shadowOffsetY={4}
          shadowBlur={8}
          cornerRadius={[6, 6, 0, 0]}
          shadowOpacity={0.5}
        />
        <Circle
          height={12}
          width={12}
          y={110}
          x={14}
          fill={robotStatusColor}
        />
        <Text
          y={103}
          x={24}
          width={80}
          text={robot_placeholder ? `${robot_placeholder} - ${shoot_robot_nick_name}` : shoot_robot_nick_name}
          align={'left'}
          fontFamily="Inter"
          fontSize={14}
          fontStyle="bold"
          ellipsis={true}
          wrap={'none'}
        />
      </Group>
      <Group>
        <Rect
          y={120}
          height={40}
          width={100}
          fill={'#7B61FF'}
          shadowColor={'#101828'}
          shadowOffsetX={0}
          shadowOffsetY={4}
          shadowBlur={8}
          cornerRadius={[0, 0, 6, 6]}
          shadowOpacity={0.1}
          visible={memberItemMoving}
        />
        <Text
          y={135}
          x={0}
          width={100}
          fill={'#ffffff'}
          text={'Drop Here'}
          align={'center'}
          fontFamily="Inter"
          fontSize={10}
          visible={memberItemMoving}
        />
        <Rect
          y={126}
          x={6}
          height={28}
          width={88}
          fill={'#7B61FF00'}
          dash={[3, 3]}
          stroke={'#fff'}
          shadowColor={'#101828'}
          shadowOffsetX={0}
          shadowOffsetY={4}
          shadowBlur={8}
          cornerRadius={6}
          shadowOpacity={0.1}
          visible={memberItemMoving}
        />
      </Group>
      <Group visible={!!operatorData?.length}>
        <Rect
          y={120}
          height={63}
          width={100}
          fill={'#7B61FF'}
          shadowColor={'#101828'}
          shadowOffsetX={0}
          shadowOffsetY={4}
          shadowBlur={8}
          cornerRadius={[0, 0, 6, 6]}
          shadowOpacity={0.1}
        />
        <Group>
          {operatorData?.map((member, index) => {
            if (index > 2) {
              return null;
            }
            return (
              <>
                <RoundedImageDefault
                  x={
                    operatorCount === 2
                      ? 5 + (index + 1) * 18
                      : operatorCount === 3
                        ? -operatorCount * 1.5 + (index + 1) * 18
                        : 33
                  }
                  y={128}
                  height={36}
                  width={36}
                  cornerRadius={18}
                  image={member.profile_image}
                  defaultImage={defaultAvatar}
                  isOnline={member?.isOnline}
                  key={member.robotId}
                />
              </>
            );
          })}
          <Circle
            height={10}
            width={10}
            y={158}
            x={operatorCount * 20 + 40 - (operatorCount - 1) * 9}
            stroke={'#fff'}
          />
        </Group>

        <Text
          y={166}
          x={0}
          width={100}
          fill={'#ffffff'}
          text={
            operatorCount > 1
              ? 'Multiple...'
              : truncate(
                  operatorData[0]?.first_name + ' ' + operatorData[0]?.last_name,
                  12,
                  '.',
                )
          }
          align={'center'}
          fontFamily="Inter"
          fontSize={10}
        />
      </Group>
    </Group>
  );
};

export default RobotCanvasItem;
