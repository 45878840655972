import React, { useEffect, useState } from 'react';
import {
  Container,
  RoleText,
  InstanceContainer,
  UserContainer,
  OnlineStatus,
  OflineStatus,
} from './styles';
import { Avatar } from '../CommonStyles';
import Icon from './Icon';
import acrynom from '../../Utils/acrynom';
import trancate from '../../Utils/trancate';
import * as types from 'Config/permissionConstant';

const UserAssignComponent = ({ image, name, isOnline }) => {
  return (
    <UserContainer>
      <Avatar bgImage={image}>{image ? null : acrynom(name)}</Avatar>
      {isOnline ? <OnlineStatus /> : <OflineStatus />}
    </UserContainer>
  );
};
const BottomInstanceNav = ({
  instances,
  onlineMembers,
  onDrop,
  onContextMenu,
  myPermission,
  crew_target_key,
  cam_target_key,
  // data: { id, lan_ip },
  onDblClick = () => {},
  onClick = () => {},
  popup = () => {},
  shootInstanceLiveStatuses,
}) => {
  const order_one = instances?.filter(instance => instance.order === 1);
  const order_two = instances?.filter(instance => instance.order === 2);
  const order_three = instances?.filter(instance => instance.order === 3);
  const order_four= instances?.filter(instance => instance.order === 4);
  const order_five = instances?.filter(instance => instance.order === 5);
  const order_six = instances?.filter(instance => instance.order === 6);

  const [orderOneIsActive, setOrderOneIsActive] = useState(false);
  const [orderTwoIsActive, setOrderTwoIsActive] = useState(false);
  const [orderThreeIsActive, setOrderThreeIsActive] = useState(false);
  const [orderFourIsActive, setOrderFourIsActive] = useState(false);
  const [orderFiveIsActive, setOrderFiveIsActive] = useState(false);
  const [orderSixIsActive, setOrderSixIsActive] = useState(false);

  const [TimeoutId, setTimeoutId] = useState(null);
  const delayMs = 1000;

  const ondblClickFunc = order => {
    let instancesArr = instances?.filter(f => f.order === order);
    let instances_id = instancesArr[0].id;
    onDblClick(instances_id, instancesArr[0], 'instance');
  };

  const onClickFunc = order => {
    let selected_instance = instances?.filter(f => f.order === order);
    onClick(selected_instance[0]?.air_id);
  };

  const handleClicks = (order) => {
    if (order === null) {
      return;
    }
    if (TimeoutId == null) {
        const tmpTimeoutId = setTimeout(() => {
            clearTimeout(tmpTimeoutId);
            setTimeoutId(null);
            onClickFunc(order);
        }, delayMs)
        setTimeoutId(tmpTimeoutId);
      } else {
        clearTimeout(TimeoutId);
        setTimeoutId(null);
        ondblClickFunc(order);
      }
  }

  const handleOnContextMenu = (e, orderList) => {
    e.preventDefault();
    if (myPermission?.includes(types.EDIT_SETUP_SCREEN) && orderList?.length) {
      popup(orderList[0]);
    }
    return;
  }

  const checkInstanceActive = (orderList, setFunc) => {
    if (orderList?.length) {
      let instanceStatusArr = shootInstanceLiveStatuses.filter(instanceStatus=> instanceStatus.air_id === orderList[0].air_id);
      if (instanceStatusArr?.length && instanceStatusArr[0]?.status === "Running" || instanceStatusArr[0]?.status === "Pending") {
        setFunc(true);
      } else {
        setFunc(false);
      }
    }
  };

  useEffect(() => {
    if (shootInstanceLiveStatuses?.length) {
      checkInstanceActive(order_one, setOrderOneIsActive);
      checkInstanceActive(order_two, setOrderTwoIsActive);
      checkInstanceActive(order_three, setOrderThreeIsActive);
      checkInstanceActive(order_four, setOrderFourIsActive);
      checkInstanceActive(order_five, setOrderFiveIsActive);
      checkInstanceActive(order_six, setOrderSixIsActive);
    }
  }, [shootInstanceLiveStatuses]);

  let lan_ip = 1;
  let id = 2;
  return (
    <Container>
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          onDrop(e, 1, order_one.length ? order_one[0].id : null);
        }}
        onClick={order_one?.length ? () => {handleClicks(1)} : null}
        onTouchEnd={order_one?.length ? () => ondblClickFunc(1) : null}
        onContextMenu={e => {handleOnContextMenu(e, order_one);}}
      >
        {order_one?.length ? (
          <>
            <RoleText>{trancate(order_one[0].nick_name, 9)}</RoleText>
            <Icon active instanceIsLive={orderOneIsActive}/>
            {order_one[0].user ? (
              <UserAssignComponent
                name={order_one[0].user.name}
                image={order_one[0].user.profile_image}
                isOnline={onlineMembers?.find(
                  item => item[order_one[0]?.user?.id] === true,
                )}
              />
            ) : null}
          </>
        ) : (
          <>
            {/* <RoleText>Switcher</RoleText> */}
            <RoleText>Instance</RoleText>
            <Icon />
          </>
        )}
      </InstanceContainer>
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          onDrop(e, 2, order_two.length ? order_two[0].id : null);
        }}
        onClick={order_two?.length ? () => {handleClicks(2)} : null}
        onTouchEnd={order_two?.length ? () => ondblClickFunc(2) : null}
        onContextMenu={e => {handleOnContextMenu(e, order_two);}}
      >
        {order_two?.length ? (
          <>
            <RoleText>{trancate(order_two[0].nick_name, 9)}</RoleText>
            <Icon active instanceIsLive={orderTwoIsActive}/>
            {order_two[0].user ? (
              <UserAssignComponent
                name={order_two[0].user.name}
                image={order_two[0].user.profile_image}
                isOnline={onlineMembers?.find(
                  item => item[order_two[0]?.user?.id] === true,
                )}
              />
            ) : null}
          </>
        ) : (
          <>
            {/* <RoleText>Switcher</RoleText> */}
            <RoleText>Instance</RoleText>
            <Icon />
          </>
        )}
      </InstanceContainer>
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          onDrop(e, 3, order_three.length ? order_three[0].id : null);
        }}
        onClick={order_three?.length ? () => {handleClicks(3)} : null}
        onTouchEnd={order_three?.length ? () => ondblClickFunc(3) : null}
        onContextMenu={e => {handleOnContextMenu(e, order_three);}}
      >
        {order_three?.length ? (
          <>
            <RoleText>{trancate(order_three[0].nick_name, 9)}</RoleText>
            <Icon active instanceIsLive={orderThreeIsActive}/>
            {order_three[0].user ? (
              <UserAssignComponent
                name={order_three[0].user.name}
                image={order_three[0].user.profile_image}
                isOnline={onlineMembers?.find(
                  item => item[order_three[0]?.user?.id] === true,
                )}
              />
            ) : null}
          </>
        ) : (
          <>
            {/* <RoleText>Switcher</RoleText> */}
            <RoleText>Instance</RoleText>
            <Icon />
          </>
        )}
      </InstanceContainer>
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          onDrop(e, 4, order_four.length ? order_four[0].id : null);
        }}
        onClick={order_four?.length ? () => {handleClicks(4)} : null}
        onTouchEnd={order_four?.length ? () => ondblClickFunc(4) : null}
        onContextMenu={e => {handleOnContextMenu(e, order_four);}}
      >
        {order_four?.length ? (
          <>
            <RoleText>{trancate(order_four[0].nick_name, 9)}</RoleText>
            <Icon active instanceIsLive={orderFourIsActive}/>
            {order_four[0].user ? (
              <UserAssignComponent
                name={order_four[0].user.name}
                image={order_four[0].user.profile_image}
                isOnline={onlineMembers?.find(
                  item => item[order_four[0]?.user?.id] === true,
                )}
              />
            ) : null}
          </>
        ) : (
          <>
            <RoleText>Instance</RoleText>
            <Icon />
          </>
        )}
      </InstanceContainer>
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          onDrop(e, 5, order_five.length ? order_five[0].id : null);
        }}
        onClick={order_five?.length ? () => {handleClicks(5)} : null}
        onTouchEnd={order_five?.length ? () => ondblClickFunc(5) : null}
        onContextMenu={e => {handleOnContextMenu(e, order_five);}}
      >
        {order_five?.length ? (
          <>
            <RoleText>{trancate(order_five[0].nick_name, 9)}</RoleText>
            <Icon active instanceIsLive={orderFiveIsActive}/>
            {order_five[0].user ? (
              <UserAssignComponent
                name={order_five[0].user.name}
                image={order_five[0].user.profile_image}
                isOnline={onlineMembers?.find(
                  item => item[order_five[0]?.user?.id] === true,
                )}
              />
            ) : null}
          </>
        ) : (
          <>
            <RoleText>Instance</RoleText>
            <Icon />
          </>
        )}
      </InstanceContainer>
      <InstanceContainer
        onDragOver={e => e.preventDefault()}
        onDrop={e => {
          onDrop(e, 6, order_six.length ? order_six[0].id : null);
        }}
        onClick={order_six?.length ? () => {handleClicks(6)} : null}
        onTouchEnd={order_six?.length ? () => ondblClickFunc(6) : null}
        onContextMenu={e => {handleOnContextMenu(e, order_six);}}
      >
        {order_six?.length ? (
          <>
            <RoleText>{trancate(order_six[0].nick_name, 9)}</RoleText>
            <Icon active instanceIsLive={orderSixIsActive}/>
            {order_six[0].user ? (
              <UserAssignComponent
                name={order_six[0].user.name}
                image={order_six[0].user.profile_image}
                isOnline={onlineMembers?.find(
                  item => item[order_six[0]?.user?.id] === true,
                )}
              />
            ) : null}
          </>
        ) : (
          <>
            <RoleText>Instance</RoleText>
            <Icon />
          </>
        )}
      </InstanceContainer>
    </Container>
  );
};

export default BottomInstanceNav;
